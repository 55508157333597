html,
body {
    padding: 0;
    margin: 0;
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background: white !important;
}

@media (prefers-color-scheme: dark) {
    html,
    body {
        background: white !important;
    }
}

@media (prefers-color-scheme: light) {
    html,
    body {
        background: white !important;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    display: none;
}

.input-placeholder input {
    font-size: 14px;
    line-height: 20px;
}